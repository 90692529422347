import $ from 'jquery'
import contentDisposition from 'content-disposition';

/*************************
 App Header
 *************************/
 
export function appheader() {
    $(".mobile-toggle").on("click", function () {
        $("body").toggleClass("sidebar-toggled");
    });
    $(document).on("click", ".mega-menu.dropdown-menu", function (a) {
        a.stopPropagation();
    });
    $(".sidebar-toggle").on("click", function () {
        $("body").toggleClass("sidebar-mini");
        $(".app-navbar").toggleClass("expand");
    });
}

export function clickOutsideSidebarEventSidebar() {
    // Mouse click event if not on sidebar
    $(document).mouseup(function (e) {
        var container = $(".sidebar-nav");
        //check if container is target
        if (!container.is(e.target) && container.has(e.target).length === 0) {
            closeSidebar();
        }
    });
}

export function closeSidebar() {
    $("body").removeClass("sidebar-toggled");
}

/*************************
 SidebarNav
 *************************/
 
export function sidebarNav() {
    let sidebarNav = $(".sidebar-nav");
    if (sidebarNav.length > 0) {
        (function (window, document, $) {
            $(function () {
                if (sidebarNav.length > 0) {
                    $('#sidebarNav').metisMenu();
                }
            });
        })(window, document, $);
    }
}

/*************************
 AppbarNav
 *************************/
 
export function appNavBar() {
    $(".app-navbar").hover(function () {
        if ($("body").hasClass("sidebar-mini")) $(".app-wrap").toggleClass("expand-navbar");
    });
}

/**********
 * Remove Empty params
 ***********/
 
export function removeEmptyParams(params) {
    if (!params) return params;
    for (const key of Object.keys(params)) {
        if (params[key] === "") {
            delete params[key];
        }
    }
    return params;
}

/********
 * Go back
 ********/
 
export function goBack(router) {
    if (window.history.length > 2) {
        router.go(-1);
    } else {
        router.push('/');
    }
}

/********
 * Go back to
 ********/
 
export function goBackTo(router, route) {
    router.push({name: route});
}

/**********
 * Create formData for file
 ***********/
 
export function createFormDataFile(obj, file) {
    let formData = new FormData();
    formData.append("item", new Blob([JSON.stringify(obj)], {
        type: "application/json"
    }));
    formData.append('file', file);
    return formData;
}

/**********
 * Create formData for files
 ***********/
 
export function createFormDataFiles(obj, files) {
    let formData = new FormData();
    formData.append("item", new Blob([JSON.stringify(obj)], {
        type: "application/json"
    }));
    Array.from(files).forEach(f => {
        formData.append('files', f);
    });
    return formData;
}

/**********
 * Download data
 ***********/
 
export function downloadData(response) {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    let fileName = contentDisposition.parse(response.headers['content-disposition']).parameters.filename;
    link.setAttribute('download', fileName);
    link.click();
    window.URL.revokeObjectURL(url);
}